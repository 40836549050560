import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController, NavParams } from '@ionic/angular';
import { AuthService } from '../../services/user/auth.service';

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.page.html',
  styleUrls: ['./confirmacao.page.scss'],
})
export class ConfirmacaoPage implements OnInit {
  public usuario: any
  title = "Confirme seu e-mail"
  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public authService: AuthService,
    public toastCtrl: ToastController,
  ) {
    this.usuario = this.navParams.data['usuario']
    // console.log(this.usuario)
  }

  ngOnInit() {
  }

  async presentToast(message, color, duration) {
    const toast = await this.toastCtrl.create({
        message,
        mode: 'ios',
        position: 'middle',
        color,
        duration,
    });
    toast.present();
  }

  SendVerificationMail(email){
    this.authService.SendVerificationMail(email).subscribe(data => {
       // console.log(data)
       let message
       if(data['cod'] == 200){
        message = "E-mail enviado com sucesso!  Se necessário, procure em sua caixa de spam ou lixo eletrônico."
       } else {
         message = "Erro no envio. Por favor, tente novamente em alguns minutos."
       }
       this.presentToast(message, 'success', 4000)
    })
  }

  closeModal(acao) {
    this.modalController.dismiss({acao, usuario:this.usuario});
  }
}
